<template>
  <div class="posBox">
    <div class="title">欢迎来到GM后台！</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.posBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 700;
  @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
    .title{
      font-size: 60px;
      background-image: -webkit-linear-gradient(left, #147B96, #E6D205 25%, #147B96 50%, #E6D205 75%, #147B96);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-background-size: 200% 100%;
      -webkit-animation: masked-animation 4s infinite linear;
    }
  }
  @-webkit-keyframes masked-animation {
    0%  { background-position: 0 0;}
    100% { background-position: -100% 0;}
  }

}
</style>